import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import Yoda from "../../../../assets/images/logo/logo.png";
import YodaDark from "../../../../assets/images/logo/logo-dark.png";
import YodaRtl from "../../../../assets/images/logo/logo-rtl.png";
import YodaRtlDark from "../../../../assets/images/logo/logo-rtl-dark.png";

import themeConfig from "../../../../configs/themeConfig.jsx";

export default function MenuLogo(props) {
  const customise = useSelector((state) => state.customise);

  return (
    <div className="vc-header-logo vc-d-flex vc-align-items-end">
      <Link to="/dashboard" onClick={props.onClose}>
        {customise.direction == "rtl" ? (
          customise.theme == "light" ? (
            <img className="vc-logo" src={YodaRtl} alt="logo" />
          ) : (
            <img className="vc-logo" src={YodaRtlDark} alt="logo" />
          )
        ) : customise.theme == "light" ? (
          <img className="vc-logo" src={Yoda} alt="logo" />
        ) : (
          <img className="vc-logo" src={YodaDark} alt="logo" />
        )}
      </Link>

      <span
        className="vc-font-weight-500 vc-text-color-primary-1 vc-mb-16 vc-ml-4 "
        style={{
          fontSize: "10px",
        }}
      >
        {themeConfig.type}
      </span>
    </div>
  );
}
