import React, { useEffect, useState } from "react";

import { Card, Row, Col, Form, Select } from "antd";
import Chart from "react-apexcharts";
import { getAll } from "../../../utils/service";
import { API_URL } from "../../../api/config";
import moment from "moment";
import { formatterNumber } from "../../../utils/input-number";

export default function OverviewPriceHistory(props) {
  const { rangeValue } = props;

  const [form] = Form.useForm();

  const [dataOverview, setDataOverview] = useState({
    series: [],
    options: {},
  });

  const getDataOverview = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "?start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }

    const res = await getAll(
      API_URL.statistic + "/chart-product-statistics" + params
    );
    if (res?.status === 200) {
      setDataOverview({
        series: res?.data?.data?.series || [],
        options: {
          chart: {
            fontFamily: "Rajdhani, sans-serif",
            type: "area",

            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          colors: [
            "#00b000",
            "#ff0022",
            "#7750f7",
            "#0010f7",
            "#ff7512",
            "#ffc212",
            "#c903ff",
          ],
          labels: {
            style: {
              fontSize: "14px",
            },
          },

          dataLabels: {
            enabled: false,
          },

          grid: {
            borderColor: "#DFE6E9",
            row: {
              opacity: 0.5,
            },
          },

          stroke: {
            curve: "smooth",
            width: 3,
          },

          markers: {
            strokeWidth: 0,
            size: 0,
            colors: [
              "#00b000",
              "#ff0022",
              "#7750f7",
              "#0010f7",
              "#ff7512",
              "#ffc212",
              "#c903ff",
            ],
            hover: {
              sizeOffset: 1,
            },
          },

          xaxis: {
            axisTicks: {
              show: false,
              borderType: "solid",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },

            tickPlacement: "between",
            labels: {
              style: {
                colors: ["636E72"],
                fontSize: "14px",
              },
            },
            categories: res?.data?.data?.labels,
          },
          legend: {
            horizontalAlign: "right",
            offsetX: 40,
            position: "top",
          },
          yaxis: {
            labels: {
              style: {
                colors: ["636E72"],
                fontSize: "14px",
              },
              formatter: function (value) {
                return "Rp " + formatterNumber(value);
              },
            },

            min: 0,
            tickAmount: 4,
          },
        },
      });
    }
  };

  const [dataCategory, setDataCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const getDataCategory = async () => {
    const res = await getAll(API_URL.category + "/all");
    if (res?.status === 200) {
      setDataCategory(res?.data?.data);
    }
  };

  const [dataProduct, setDataProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const getDataProduct = async () => {
    if (selectedCategory && selectedCategory?.length > 0) {
      let arrDataProduct = [];
      await Promise.all(
        selectedCategory?.map(async (item, index) => {
          const res = await getAll(
            API_URL.product + "/all?category_code=" + item
          );
          if (res?.status === 200) {
            arrDataProduct.push(...res?.data?.data);
          }
        })
      );

      setDataProduct(arrDataProduct);
    }
  };

  useEffect(() => {
    getDataCategory();
  }, []);

  useEffect(() => {
    getDataProduct();
  }, [selectedCategory]);

  useEffect(() => {
    getDataOverview();
  }, [selectedCategory, selectedProduct, rangeValue]);

  return (
    <Card className="vc-border-color-black-40 vc-mb-24">
      <Form
        form={form}
        id="myForm"
        layout="vertical"
        name="basic"
        className="vc-mb-16"
      >
        <Row align="middle" gutter={[8, 8]}>
          <Col span={24}>
            <h4>Statistik Produk</h4>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item label="Kategori" className="vc-mb-0" name="category_id">
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Semua Kategori"
                mode="multiple"
                onChange={(value) => setSelectedCategory(value)}
              >
                {dataCategory?.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item label="Produk" className="vc-mb-0" name="product_id">
              <Select placeholder="Semua Produk" mode="multiple">
                {dataProduct?.map((item) => (
                  <Option value={item.product_id} key={item.product_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div id="chart">
        <Chart
          options={dataOverview.options}
          series={dataOverview.series}
          type="area"
          height="260px"
          legend="legend"
        />
      </div>
    </Card>
  );
}
