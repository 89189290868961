import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Divider, Row, Modal } from "antd";

import avatarImg from "../../../assets/images/default-profile.svg";
import { autoLogout } from "../../../utils/logout";
import themeConfig from "../../../configs/themeConfig";
import { Call, Home } from "react-iconly";

export default function HeaderUser() {
  const { confirm } = Modal;
  const storage = JSON.parse(localStorage.getItem("loginSession"));

  const handleLogout = () => {
    confirm({
      title: (
        <h5 className="vc-mb-0 vc-font-weight-500">
          Apakah Anda yakin akan Logout?
        </h5>
      ),
      icon: false,
      okText: "Logout",
      cancelText: "Batal",
      onOk() {
        autoLogout();
      },
      onCancel() {},
    });
  };

  const menu = (
    <div
      className="vc-border-radius vc-border-1 vc-border-color-black-40 vc-bg-black-0 vc-bg-dark-100 vc-border-color-dark-80 vc-p-24 vc-mt-12"
      style={{ width: 260 }}
    >
      <span className="vc-d-block h5 vc-text-color-black-100 vc-text-color-dark-0 vc-mb-8">
        Hello{" "}
        <span style={{ textTransform: "capitalize" }}>
          {storage?.name ?? ""}
        </span>
      </span>

      <Divider className="vc-my-8" />

      <Row>
        <Col span={24}>
          <a
            href={process.env.REACT_APP_DOMAIN_URL}
            target="_blank"
            className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Home set="curved" size={16} />

            <span className="vc-ml-8">Beranda</span>
          </a>
        </Col>
        <Col span={24}>
          <a
            href={"https://wa.me/" + themeConfig.whatsapp}
            target="_blank"
            className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Call set="curved" size={16} />

            <span className="vc-ml-8">Hubungi Admin</span>
          </a>
        </Col>
      </Row>

      <Divider className="vc-my-8" />

      <a
        onClick={() => {
          handleLogout();
        }}
        className="vc-p1-body vc-text-color-danger-1"
      >
        Logout
      </a>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar src={avatarImg} size={40} className="vc-cursor-pointer" />
      </Dropdown>
    </Col>
  );
}
