import React, { useEffect, useState } from "react";

import { Card, Col, Row, DatePicker, Form, Divider, Table } from "antd";
import { useSelector } from "react-redux";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";

import cardImg from "../../../assets/images/dashboard-banner.svg";
import cardImgDark from "../../../assets/images/dashboard-banner.png";
import { formatterNumber } from "../../../utils/input-number";
import {
  Buy,
  Document,
  Paper,
  People,
  Plus,
  TickSquare,
  TicketStar,
  Wallet,
} from "react-iconly";
import { getAll } from "../../../utils/service";
import { API_URL } from "../../../api/config";
import "moment/locale/id";
import moment from "moment";
import locale from "antd/es/date-picker/locale/id_ID";
import Overview from "./_overview";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const { RangePicker } = DatePicker;

export default function Home() {
  const [form] = Form.useForm();
  const theme = useSelector((state) => state.customise.theme);

  // Privilege
  const data = JSON.parse(localStorage.getItem("loginSession"));
  const permissions = data?.access;
  const access = ["04"];

  // Data Sales
  const [dataSales, setDataSales] = useState(0);

  const getDataSales = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "&start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }

    const res = await getAll(
      API_URL.statistic + "/idr-monthly-sales?year_month=" + params
    );
    if (res?.status === 200) {
      setDataSales(res.data.data.value);
    }
  };

  // Topup

  const [dataTopup, setDataTopup] = useState(0);

  const getDataTopup = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "&start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }
    const res = await getAll(
      API_URL.statistic + "/idr-monthly-topup?year_month=" + params
    );
    if (res?.status === 200) {
      setDataTopup(res.data.data.value);
    }
  };

  // Trx Succes

  const [dataTrxSuccess, setDataTrxSuccess] = useState(0);

  const getDataTrxSuccess = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "&start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }
    const res = await getAll(
      API_URL.statistic +
        "/qty-monthly-transaction-success?year_month=" +
        params
    );
    if (res?.status === 200) {
      setDataTrxSuccess(res.data.data.value);
    }
  };

  // Trx Failed

  const [dataTrxFailed, setDataTrxFailed] = useState(0);

  const getDataTrxFailed = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "&start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }
    const res = await getAll(
      API_URL.statistic + "/qty-monthly-transaction-failed?year_month=" + params
    );
    if (res?.status === 200) {
      setDataTrxFailed(res.data.data.value);
    }
  };

  // Referral Commission

  const [dataReferralCommission, setDataReferralCommission] = useState(0);

  const getDataReferralCommission = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "&start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }
    const res = await getAll(
      API_URL.statistic + "/idr-monthly-referral-comission?year_month=" + params
    );
    if (res?.status === 200) {
      setDataReferralCommission(res.data.data.value);
    }
  };

  // Total Mitra Active

  const [totalMitraActive, setTotalMitraActive] = useState(0);
  const [totalMitraVerified, setTotalMitraVerified] = useState(0);

  const getTotalMitraActive = async () => {
    const res = await getAll(API_URL.statistic + "/mitra-summary");
    if (res?.status === 200) {
      setTotalMitraActive(res.data.data.total_active);
      setTotalMitraVerified(res.data.data.total_verified);
    }
  };

  // Table
  const columnsMitra = [
    {
      title: "Username",
      dataIndex: "vendor_name",
      key: "vendor_name",
      width: "50%",
    },
    {
      title: "Sales",
      dataIndex: "total_sales",
      key: "total_sales",
      width: "50%",
      render: (total_sales) => "Rp " + formatterNumber(total_sales),
    },
  ];

  const columnsSales = [
    {
      title: "Grup Kategori",
      dataIndex: "product_name",
      key: "product_name",
      width: "50%",
    },
    {
      title: "Sales",
      dataIndex: "total_sales",
      key: "total_sales",
      width: "50%",
      render: (total_sales) => "Rp " + formatterNumber(total_sales),
    },
  ];

  const [dataTopTenMitra, setDataTopTenMitra] = useState([]);

  const getDataTopTenMitra = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "&start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }
    const res = await getAll(
      API_URL.statistic + "/top-ten-monthly-mitra-sales?year_month=" + params
    );
    if (res?.status === 200) {
      setDataTopTenMitra(res.data.data);
    }
  };

  const [dataTopTenSales, setDataTopTenSales] = useState([]);

  const getDataTopTenSales = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "&start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }
    const res = await getAll(
      API_URL.statistic + "/top-ten-monthly-product-sales?year_month=" + params
    );
    if (res?.status === 200) {
      setDataTopTenSales(res.data.data);
    }
  };

  // Limit 30 Days RangePicker
  const [rangeDates, setRangeDates] = useState(null);
  const [rangeValue, setRangeValue] = useState([
    moment().subtract(30, "days"),
    moment(),
  ]);

  const disabledDate = (current) => {
    if (!rangeDates) {
      return false;
    }
    const tooLate = rangeDates[0] && current.diff(rangeDates[0], "days") >= 30;
    const tooEarly = rangeDates[1] && rangeDates[1].diff(current, "days") >= 30;
    return !!tooEarly || !!tooLate || current > moment();
  };

  const onOpenChange = (open) => {
    if (open) {
      form.setFieldsValue({
        range_date: undefined,
      });
      setRangeDates([null, null]);
    } else {
      setRangeDates(null);
    }
  };

  useEffect(() => {
    getDataSales();
    getDataTopup();
    getDataTrxSuccess();
    getDataTrxFailed();
    getDataReferralCommission();

    getDataTopTenSales();
    getDataTopTenMitra();

    getTotalMitraActive();
    document.title = "Dashboard - " + themeConfig.appname;
  }, [rangeValue]);

  return permissions?.some(
    (data) => access?.includes(data) || access === null
  ) ? (
    <Row gutter={[16, 16]} className="vc-mb-24">
      <PageTitle pageTitle="Dashboard">
        <div className="vc-d-flex vc-align-items-center">
          <Form form={form} id="myForm" layout="horizontal" name="basic">
            <Form.Item
              label="Tanggal"
              className="vc-mb-0"
              name="range_date"
              initialValue={rangeValue}
            >
              <RangePicker
                className="vc-w-100"
                autoComplete="off"
                placement="bottomLeft"
                placeholder={["Tgl. Awal", "Tgl. Akhir"]}
                format={"DD-MM-YYYY"}
                value={rangeDates || rangeValue}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setRangeDates(val)}
                onChange={(val) => setRangeValue(val)}
                onOpenChange={onOpenChange}
                renderExtraFooter={() => "Maksimal range tanggal 30 hari"}
              />
            </Form.Item>
          </Form>
        </div>
      </PageTitle>

      <Col span={24} xl={6} lg={12}>
        <Link to="/transaction/data">
          <Card
            className="vc-border-color-black-40 vc-h-100"
            bodyStyle={{ padding: "16px" }}
          >
            <Row className="vc-align-items-center">
              <Col className="vc-statistic-icon-bg vc-mr-16 vc-mb-xs-16 vc-bg-color-primary-4 vc-bg-color-dark-primary">
                <Buy
                  className="vc-text-color-primary-1 vc-text-color-dark-primary-2 remix-icon"
                  size={24}
                />
              </Col>
              <Col span={24} className="vc-mt-8">
                <p className="vc-p1-body vc-mb-0 vc-text-color-black-80 vc-text-color-dark-30">
                  Sales
                </p>
                <h5 className="vc-mb-4">
                  Rp{" "}
                  <span data-testid="data-sales">
                    {formatterNumber(dataSales)}
                  </span>
                </h5>
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>

      <Col span={24} xl={6} lg={12}>
        <Link to="/topup/data">
          <Card
            className="vc-border-color-black-40 vc-h-100"
            bodyStyle={{ padding: "16px" }}
          >
            <Row className="vc-align-items-center">
              <Col className="vc-statistic-icon-bg vc-mr-16 vc-mb-xs-16 vc-bg-color-primary-4 vc-bg-color-dark-primary">
                <TicketStar
                  className="vc-text-color-primary-1 vc-text-color-dark-primary-2 remix-icon"
                  size={24}
                />
              </Col>
              <Col span={24} className="vc-mt-8">
                <p className="vc-p1-body vc-mb-0 vc-text-color-black-80 vc-text-color-dark-30">
                  Topup Mitra
                </p>
                <h5 className="vc-mb-4">
                  Rp{" "}
                  <span data-testid="data-topup-mitra">
                    {formatterNumber(dataTopup)}
                  </span>
                </h5>
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col span={24} xl={6} lg={12}>
        <Link to="/transaction/data">
          <Card
            className="vc-border-color-black-40 vc-h-100"
            bodyStyle={{ padding: "16px" }}
          >
            <Row className="vc-align-items-center">
              <Col className="vc-statistic-icon-bg vc-mr-16 vc-mb-xs-16 vc-bg-color-success-4 vc-bg-color-dark-success">
                <Document
                  className="vc-text-color-success-1 vc-text-color-dark-success-2 remix-icon"
                  size={24}
                />
              </Col>
              <Col span={24} className="vc-mt-8">
                <p className="vc-p1-body vc-mb-0 vc-text-color-black-80 vc-text-color-dark-30">
                  Transaction Success
                </p>
                <h5 className="vc-mb-4">
                  <span data-testid="data-transaction-success">
                    {formatterNumber(dataTrxSuccess)}
                  </span>
                </h5>
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col span={24} xl={6} lg={12}>
        <Link to="/transaction/data">
          <Card
            className="vc-border-color-black-40 vc-h-100"
            bodyStyle={{ padding: "16px" }}
          >
            <Row className="vc-align-items-center">
              <Col className="vc-statistic-icon-bg vc-mr-16 vc-mb-xs-16 vc-bg-color-danger-4 vc-bg-color-dark-danger">
                <Document
                  className="vc-text-color-danger-1 vc-text-color-dark-danger-2 remix-icon"
                  size={24}
                />
              </Col>
              <Col span={24} className="vc-mt-8">
                <p className="vc-p1-body vc-mb-0 vc-text-color-black-80 vc-text-color-dark-30">
                  Transaction Failed
                </p>
                <h5 className="vc-mb-4">
                  <span data-testid="data-transaction-failed">
                    {formatterNumber(dataTrxFailed)}
                  </span>
                </h5>
              </Col>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col span={24}>
        <Divider className="vc-my-8" />
      </Col>
      <Col span={24} lg={18}>
        <Overview rangeValue={rangeValue} />
      </Col>
      <Col span={24} lg={6}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Link to="/referral/data">
              <Card
                className="vc-border-color-black-40 vc-h-100"
                bodyStyle={{ padding: "16px" }}
              >
                <Row className="vc-align-items-center">
                  <Col className="vc-statistic-icon-bg vc-mr-16 vc-mb-xs-16 vc-bg-color-warning-4 vc-bg-color-dark-warning">
                    <Wallet
                      className="vc-text-color-warning-1 vc-text-color-dark-warning-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col span={24} className="vc-mt-8">
                    <p className="vc-p1-body vc-mb-0 vc-text-color-black-80 vc-text-color-dark-30">
                      Referral Commission
                    </p>
                    <h5 className="vc-mb-4">
                      Rp{" "}
                      <span data-testid="data-referral-comission">
                        {formatterNumber(dataReferralCommission)}
                      </span>
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col span={24}>
            <Link to="/mitra/data">
              <Card
                className="vc-border-color-black-40 vc-h-100"
                bodyStyle={{ padding: "16px" }}
              >
                <Row className="vc-align-items-center">
                  <Col className="vc-statistic-icon-bg vc-mr-16 vc-mb-xs-16 vc-bg-color-primary-4 vc-bg-color-dark-primary">
                    <People
                      className="vc-text-color-primary-1 vc-text-color-dark-primary-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col span={24} className="vc-mt-8">
                    <p className="vc-p1-body vc-mb-0 vc-text-color-black-80 vc-text-color-dark-30">
                      Total Mitra Aktif
                    </p>
                    <h5 className="vc-mb-4">
                      <span data-testid="total-mitra-active">
                        {formatterNumber(totalMitraActive)}
                      </span>
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col span={24}>
            <Link to="/mitra/data">
              <Card
                className="vc-border-color-black-40 vc-h-100"
                bodyStyle={{ padding: "16px" }}
              >
                <Row className="vc-align-items-center">
                  <Col className="vc-statistic-icon-bg vc-mr-16 vc-mb-xs-16 vc-bg-color-success-4 vc-bg-color-dark-success">
                    <TickSquare
                      className="vc-text-color-success-1 vc-text-color-dark-success-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col span={24} className="vc-mt-8">
                    <p className="vc-p1-body vc-mb-0 vc-text-color-black-80 vc-text-color-dark-30">
                      Total Mitra Verified
                    </p>
                    <h5 className="vc-mb-4">
                      <span data-testid="total-mitra-verified">
                        {formatterNumber(totalMitraVerified)}
                      </span>
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider className="vc-my-8" />
      </Col>
      <Col span={24} lg={12}>
        <Card className="vc-border-color-black-10  vc-h-100">
          <div className="vc-mb-16">
            <h5 className="vc-mb-0">Top 10 Mitra</h5>
          </div>

          <Table
            data-testid={"data-top-10-mitra"}
            columns={columnsMitra}
            dataSource={dataTopTenMitra}
            rowKey={"id"}
            pagination={false}
            scroll={{ x: 293 }}
          />
        </Card>
      </Col>
      <Col span={24} lg={12}>
        <Card className="vc-border-color-black-10 vc-h-100">
          <div className="vc-mb-16">
            <h5 className="vc-mb-0">Top 10 Sales Game</h5>
          </div>

          <Table
            data-testid={"data-top-10-sales-game"}
            columns={columnsSales}
            dataSource={dataTopTenSales}
            rowKey={"id"}
            pagination={false}
            scroll={{ x: 293 }}
          />
        </Card>
      </Col>
    </Row>
  ) : (
    <Row gutter={[16, 16]}>
      <PageTitle pageTitle="Dashboard" className="vc-mb-24" />
      <Col span={24}>
        <Card
          className="vc-border-color-black-40 vc-card-2 vc-upgradePlanCardOne vc-upgradePlanCardOne-bg vc-mb-24"
          style={{
            backgroundImage: `url(${theme == "dark" ? cardImgDark : cardImg})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        >
          <Row align="middle" className="vc-mt-8">
            <Col span={24} className="vc-mb-4">
              <Row align="middle" justify="space-between">
                <Col flex="1">
                  <h2 className="vc-mb-8">
                    Selamat Datang {data?.name ?? ""} 👋
                  </h2>

                  <h4 className="vc-mb-0">
                    {moment(new Date()).format("DD MMMM YYYY")}
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
