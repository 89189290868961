const themeConfig = {
  appname: "H2H Admin VCGamers",
  company: "PT. Sotta Teknologi Indonesia",
  version: "1.0",
  type: "ADMIN",
  theme: localStorage.getItem("theme") === "dark" ? "dark" : "light",
  contentWidth: "full",
  sidebarCollapsed: false,
  sidebarCollapseButton: true,
  layout: "VerticalLayout",
  navigationFull: false,
  navigationBg: false,
  direction: "ltr",
  language: "id",
  whatsapp: "628159021021",
};

export default themeConfig;
