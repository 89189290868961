import React, { createContext, useContext, useState, useEffect } from "react";
import { WEBSOCKET_URL } from "./api/config";

import { notification } from "antd";

import notifRingtone from "./assets/audio/ringtone.wav";
import { Document, Game, InfoCircle, Paper, Plus, Ticket } from "react-iconly";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WebSocketNotifContext = createContext();

export const WebSocketNotifProvider = ({ children }) => {
  const history = useHistory();

  const [webSocket, setWebSocket] = useState([]);

  const websocketNotification = () => {
    var socket = new WebSocket(WEBSOCKET_URL.notification);

    socket.onmessage = function (event) {
      setWebSocket(event.data);
      openNotification(JSON.parse(event.data));
    };

    socket.onclose = function (e) {
      checkSession();
    };
    window.onbeforeunload = (event) => {
      socket.close();
    };
  };

  const handleNotificationClick = (clickable, url) => {
    if (clickable === true) {
      window.location.href = url;
    }
  };

  const openNotification = (data) => {
    const audio = new Audio(notifRingtone);
    audio.volume = 1;
    audio.play();

    if (data.type === "kyc") {
      notification.open({
        message: data.title,
        description: data.description,
        placement: "topRight",
        duration: 0,
        icon: <Paper size={24} className={`vc-text-color-success-1`} />,
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/mitra/detail?q=" + data.clickable_id
          ),
      });
    } else if (data.type === "transaction") {
      notification.open({
        message: data.title,
        description: data.description,
        placement: "topRight",
        duration: 0,
        icon: (
          <Document size={24} className={`vc-text-color-${data.color}-1`} />
        ),
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/transaction/detail?q=" + data.clickable_id
          ),
      });
    } else if (data.type === "ticket") {
      notification.open({
        message: data.title,
        description: data.description,
        placement: "topRight",
        duration: 0,
        icon: <Ticket size={24} className={`vc-text-color-primary-1`} />,
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/ticket/detail?q=" + data.clickable_id
          ),
      });
    } else if (data.type === "ticket-reply") {
      notification.open({
        message: data.title,
        description: data.description,
        placement: "topRight",
        duration: 0,
        icon: <Ticket size={24} className={`vc-text-color-primary-1`} />,
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/ticket/detail?q=" + data.clickable_id
          ),
      });
      // } else if (data.type === "product") {
      //   notification.open({
      //     message: data.title,
      //     description: data.description,
      //     placement: "topRight",
      //     duration: 0,
      //     icon: <Game size={24} className={`vc-text-color-${data.color}-1`} />,
      //   });
    } else if (data.type === "topup") {
      notification.open({
        message: data.title,
        description: data.description,
        placement: "topRight",
        duration: 0,
        icon: <Plus size={24} className={`vc-text-color-${data.color}-1`} />,
        onClick: () =>
          handleNotificationClick(
            data.is_clickable,
            "/topup/detail?q=" + data.clickable_id
          ),
      });
    }
  };

  const checkSession = () => {
    const storage = localStorage.getItem("loginSession");
    if (storage) {
      const token = JSON.parse(storage).accessToken;
      websocketNotification();
    }
  };

  // useEffect
  useEffect(() => {
    checkSession();
  }, []);

  return (
    <WebSocketNotifContext.Provider value={webSocket}>
      {children}
    </WebSocketNotifContext.Provider>
  );
};

export const useWebSocketNotif = () => {
  return useContext(WebSocketNotifContext);
};
