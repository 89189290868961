import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    auth: false,
  },
  // MAIN PAGES
  {
    path: "/dashboard",
    exact: true,
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
    auth: true,
    kyc: false,
    access: null,
  },
  {
    path: "/mitra/data",
    component: lazy(() => import("../../view/pages/mitra/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["05", "06"],
  },
  {
    path: "/mitra/detail",
    component: lazy(() => import("../../view/pages/mitra/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: ["05", "09", "090", "091", "10", "11", "12", "13", "14", "140"],
  },
  {
    path: "/mitra/form",
    component: lazy(() => import("../../view/pages/mitra/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["05", "15"],
  },
  {
    path: "/category/data",
    component: lazy(() => import("../../view/pages/category/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["17001", "23"],
  },
  {
    path: "/category/detail",
    component: lazy(() => import("../../view/pages/category/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: ["17", "23"],
  },
  {
    path: "/category/parent",
    component: lazy(() => import("../../view/pages/category/parent")),
    layout: "VerticalLayout",
    auth: true,
    access: ["1701", "17014"],
  },
  {
    path: "/category/parent-form",
    component: lazy(() => import("../../view/pages/category/parent-form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["17011", "17012"],
  },
  {
    path: "/category/group",
    component: lazy(() => import("../../view/pages/category/group")),
    layout: "VerticalLayout",
    auth: true,
    access: ["1701", "17014"],
  },
  {
    path: "/category/group-form",
    component: lazy(() => import("../../view/pages/category/group-form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["17011", "17012"],
  },
  {
    path: "/category/detail-relation",
    component: lazy(() => import("../../view/pages/category/relation/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/category/status",
    component: lazy(() => import("../../view/pages/category/status")),
    layout: "VerticalLayout",
    auth: true,
    access: ["1701", "17014"],
  },
  {
    path: "/topup/data",
    component: lazy(() => import("../../view/pages/topup/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["68", "680"],
  },
  {
    path: "/topup/detail",
    component: lazy(() => import("../../view/pages/topup/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: ["114", "683"],
  },
  {
    path: "/topup/form",
    component: lazy(() => import("../../view/pages/topup/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["110", "113"],
  },
  {
    path: "/referral/data",
    component: lazy(() => import("../../view/pages/referral/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/product/mitra/form",
    component: lazy(() => import("../../view/pages/product/form-mitra")),
    layout: "VerticalLayout",
    auth: true,
    access: ["050"],
  },
  {
    path: "/category/form",
    component: lazy(() => import("../../view/pages/category/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["17", "25"],
  },
  {
    path: "/product/data",
    component: lazy(() => import("../../view/pages/product/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["27", "33"],
  },
  {
    path: "/product/detail",
    component: lazy(() => import("../../view/pages/product/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: ["27", "34"],
  },
  {
    path: "/product/price-history",
    component: lazy(() => import("../../view/pages/product/price-history")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/product/form",
    component: lazy(() => import("../../view/pages/product/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["27", "36"],
  },
  {
    path: "/product-price-history/supplier",
    component: lazy(() => import("../../view/pages/product/history/supplier")),
    layout: "VerticalLayout",
    auth: true,
    access: ["27", "33"],
  },
  {
    path: "/product-price-history/tier",
    component: lazy(() => import("../../view/pages/product/history/tier")),
    layout: "VerticalLayout",
    auth: true,
    access: ["27", "33"],
  },
  {
    path: "/transaction/data",
    component: lazy(() => import("../../view/pages/transaction/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["38", "39"],
  },
  {
    path: "/transaction/detail",
    component: lazy(() => import("../../view/pages/transaction/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: ["38", "41", "100"],
  },

  {
    path: "/ticket/data",
    component: lazy(() => import("../../view/pages/ticket/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["59", "60"],
  },
  {
    path: "/ticket/detail",
    component: lazy(() => import("../../view/pages/ticket/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: ["59", "61"],
  },
  {
    path: "/feedback",
    component: lazy(() => import("../../view/pages/feedback")),
    layout: "VerticalLayout",
    auth: true,
    access: ["63", "630"],
  },
  {
    path: "/notification",
    component: lazy(() => import("../../view/pages/notification")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  {
    path: "/activity-log",
    component: lazy(() => import("../../view/pages/activity-log")),
    layout: "VerticalLayout",
    auth: true,
    access: ["53", "54"],
  },
  {
    path: "/admin-list/form",
    component: lazy(() => import("../../view/pages/user/admin-form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["45", "46"],
  },
  {
    path: "/admin-list/data",
    component: lazy(() => import("../../view/pages/user/admin-list")),
    layout: "VerticalLayout",
    auth: true,
    access: ["44", "4401"],
  },
  {
    path: "/role/data",
    component: lazy(() => import("../../view/pages/user/role-list")),
    layout: "VerticalLayout",
    auth: true,
    access: ["48", "49"],
  },
  {
    path: "/role/form",
    component: lazy(() => import("../../view/pages/user/role-form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["44", "48", "50", "51"],
  },
  {
    path: "/payment-method/xendit",
    component: lazy(() => import("../../view/pages/payment-method/xendit")),
    layout: "VerticalLayout",
    auth: true,
    access: ["66", "660"],
  },
  {
    path: "/payment-method/manual/data",
    component: lazy(() =>
      import("../../view/pages/payment-method/manual/data")
    ),
    layout: "VerticalLayout",
    auth: true,
    access: ["66", "660"],
  },
  {
    path: "/payment-method/manual/form",
    component: lazy(() =>
      import("../../view/pages/payment-method/manual/form")
    ),
    layout: "VerticalLayout",
    auth: true,
    access: ["66", "660"],
  },
  // {
  //   path: "/referral-commission",
  //   component: lazy(() => import("../../view/pages/referral-commission")),
  //   layout: "VerticalLayout",
  //   auth: true,
  //   access: ["57"],
  // },
  {
    path: "/onboarding",
    component: lazy(() => import("../../view/pages/onboarding")),
    layout: "VerticalLayout",
    auth: true,
    access: ["67", "670"],
  },
  {
    path: "/changelog/data",
    component: lazy(() => import("../../view/pages/changelog/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/changelog/form",
    component: lazy(() => import("../../view/pages/changelog/form")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/changelog/detail",
    component: lazy(() => import("../../view/pages/changelog/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },

  {
    path: "/voucher-phone-number/data",
    component: lazy(() => import("../../view/pages/voucher-phone-number/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/voucher-phone-number/form",
    component: lazy(() => import("../../view/pages/voucher-phone-number/form")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/tier/data",
    component: lazy(() => import("../../view/pages/tier/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/tier/form",
    component: lazy(() => import("../../view/pages/tier/form")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/tier/detail",
    component: lazy(() => import("../../view/pages/tier/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/tier/product-group/data",
    component: lazy(() => import("../../view/pages/tier/product-group/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/tier/product-group/form",
    component: lazy(() => import("../../view/pages/tier/product-group/form")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/tier/product-group/detail",
    component: lazy(() => import("../../view/pages/tier/product-group/detail")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/profile",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    auth: true,
    access: ["69", "691"],
  },
  {
    path: "/trash/category",
    component: lazy(() => import("../../view/pages/trash/category")),
    layout: "VerticalLayout",
    auth: true,
    access: ["641", "6421"],
  },
  {
    path: "/trash/product",
    component: lazy(() => import("../../view/pages/trash/product")),
    layout: "VerticalLayout",
    auth: true,
    access: ["643", "6430"],
  },

  // {
  //   path: "/file-preview",
  //   component: lazy(() => import("../../view/pages/file-preview")),
  //   layout: "FullLayout",
  //   auth: true,
  //   access: null,
  // },

  // ACCESS PANEL
  {
    path: "/panel/payment-method/data",
    component: lazy(() => import("../../view/panel/payment-method/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
  {
    path: "/panel/history-balance",
    component: lazy(() => import("../../view/panel/history-balance/data")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
    // NOT SET
  },
];

export default PagesRoutes;
