export const formatterNumber = (val) => {
  if (!val) return 0;
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(2);
};

export const toHoursAndMinutes = (totalSeconds) => {
  let result = "";
  const totalMinutes = Math.floor(totalSeconds / 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  if (hours > 0) {
    result += hours + " jam ";
  }

  if (minutes >= 0 && hours > 0 && seconds > 0) {
    result += minutes + " menit ";
  } else if (minutes > 0) {
    result += minutes + " menit ";
  }

  if (seconds > 0) {
    result += seconds + " detik";
  }

  return result;
};
