import axios from "axios";
import { API_URL } from "../api/config";
import { autoLogout } from "./logout";

export async function getPaginate(
  url,
  page_length = 10,
  page = 1,
  search = "",
  params = ""
) {
  let searchValue = "";
  if (search !== "") {
    searchValue = "&search=" + search;
  }
  const res = axios
    .get(
      url + "?limit=" + page_length + "&page=" + page + searchValue + params,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("loginSession")).accessToken
          }`,
          Accept: "application/json",
          "Content-Type": "application/json;",
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function getAll(url, params = "") {
  const res = axios
    .get(url + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function show(url) {
  const res = axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function get(url, id, params = "") {
  const res = axios
    .get(url + "/" + id + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function detail(url, id, params = "") {
  const res = axios
    .get(url + "/detail/" + id + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function post(url, value = []) {
  const res = await axios
    .post(url, JSON.stringify(value), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function put(url, id, value) {
  const res = axios
    .put(url + `/${id}`, JSON.stringify(value), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function puts(url, value) {
  const res = axios
    .put(url, JSON.stringify(value), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function patch(url, id, value) {
  const res = axios
    .patch(url + `/${id}`, JSON.stringify(value), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function del(url, id = null) {
  const res = axios
    .delete(url + `/${id}`, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function postFormData(url, value) {
  const res = await axios
    .post(url, value, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function putFormData(url, id, value) {
  const res = axios
    .post(url + `/${id}?_method=PUT`, value, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function exportData(
  url,
  search = "",
  params = "",
  filename,
  extension
) {
  const res = axios
    .get(url + "?search=" + search + params, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("loginSession")).accessToken
        }`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename + "." + extension);
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}
