export const api_base_url = process.env.REACT_APP_BASE_URL;
export const api_websocket_url = process.env.REACT_APP_WEBSOCKET_URL;
export const api_base_url_v1 = process.env.REACT_APP_BASE_URL + "/v1";
export const api_base_url_admin = api_base_url_v1 + "/admin";
export const api_base_url_mitra = api_base_url_v1 + "/vendor";
export const api_base_url_panel = api_base_url_v1 + "/admin/panel";

export const API_URL = {
  login: api_base_url_admin + "/login",
  uploader: api_base_url + "/uploader",

  notification: api_base_url_v1 + "/admin-notifications",

  dashboard: api_base_url_admin + "/dashboard",
  statistic: api_base_url_admin + "/statistic",

  transaction: api_base_url_v1 + "/transaction",
  mitra: api_base_url_v1 + "/vendor",
  product: api_base_url_admin + "/product",
  priceHistory: api_base_url_admin + "/price-history",
  category: api_base_url_admin + "/category",
  parentCategory: api_base_url_admin + "/parent-category",
  parentCategoryLabel: api_base_url_admin + "/parent-category-label",
  topup: api_base_url_v1 + "/topup",
  balance_history: api_base_url_admin + "/vendor/balance_history",
  referral: api_base_url_mitra + "/referral",
  all_referral: api_base_url_mitra + "/referrals",
  approve_kyc: api_base_url_mitra + "/approve_kyc",
  reject_kyc: api_base_url_mitra + "/reject_kyc",
  access_api: api_base_url_mitra + "/grant-access-api",
  api_privilege: api_base_url_mitra + "/api",

  feedback: api_base_url_mitra + "/feedback",
  ticket: api_base_url_admin + "/ticket-system",
  activity_log: api_base_url_v1 + "/activity_log",
  admin: api_base_url_admin,
  role: api_base_url_admin + "/role",
  privilege: api_base_url_admin + "/privileges-tree",
  profile: api_base_url_admin + "/profile",
  setting: api_base_url_admin + "/setting",
  markup: api_base_url_v1 + "/setting/markup_setting",
  payment_xendit: api_base_url_v1 + "/setting/payment-methods",
  payment_manuals: api_base_url_v1 + "/setting/payment-manual-methods",
  payment_manual: api_base_url_v1 + "/setting/payment-manual-method",
  onboarding: api_base_url_v1 + "/setting/onboarding",
  changelog: api_base_url_v1 + "/setting/changelog",
  tier: api_base_url_v1 + "/setting/vendor-tier",
  product_group: api_base_url_v1 + "/setting/item-group",
  update_referral: api_base_url_v1 + "/setting/update_markup_setting",
  voucher_phone: api_base_url_v1 + "/setting/voucher-phone",

  sync_product_VENDOR: api_base_url_admin + "/product/vendor/sync_product",
  reset_product_VENDOR:
    api_base_url_admin + "/product/vendor/reset-product-price",

  sync_category_LG: api_base_url_v1 + "/lg/sync_category",
  sync_product_LG: api_base_url_v1 + "/lg/sync_product",
  sync_category_DF: api_base_url_admin + "/category/sync-digiflazz",
  sync_product_DF: api_base_url_admin + "/product/sync-digiflazz",
  sync_category_MARKET: api_base_url_admin + "/category/sync-vcmarket",
  sync_product_MARKET: api_base_url_admin + "/product/sync-vcmarket",

  panel: api_base_url_mitra + "/panel",
};

export const API_URL_PANEL = {
  payment_method: api_base_url_panel + "/payment-method",
  balance_history: api_base_url_panel + "/member/balance-history",
};

export const API_PLUGIN_URL = {
  check_id_connect: api_base_url_admin + "/category/activate-check-game",
  check_id_disconnect: api_base_url_admin + "/category/deactivate-check-game",
  check_id_status: api_base_url_admin + "/category/set-status-check-game",
  check_id_get_status: api_base_url_admin + "/get-global-check-id-status",
  check_id_set_status: api_base_url_admin + "/set-global-check-id-status",
  check_id_product: api_base_url_admin + "/check-id/products",
  check_id_field: api_base_url_admin + "/check-id/fields",
  check_id_option: api_base_url_admin + "/check-id/options",
};

export const WEBSOCKET_URL = {
  notification: api_websocket_url + "/admin-notification",
};
