import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col, Badge } from "antd";
import { RiCloseLine, RiMenuFill } from "react-icons/ri";

import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import HeaderText from "./HeaderText";
import HeaderTheme from "./HeaderTheme";
import { Notification, Search } from "react-iconly";
import { useHistory } from "react-router-dom";
import HeaderSearch from "./HeaderSearch";

const { Header } = Layout;

export default function MenuHeader(props) {
  const history = useHistory();

  const { notifData, setVisible } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: "start",
      });
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="vc-w-100 vc-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="vc-mobile-sidebar-button vc-mr-24">
          <Button
            className="vc-mobile-sidebar-button"
            type="text"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon vc-text-color-black-80 vc-text-color-dark-30"
              />
            }
          />
        </Col>

        <Col
          flex="1"
          style={{ display: !searchHeader ? "none" : "block" }}
          className={`vc-pr-md-0 vc-pr-16 vc-header-search ${
            searchActive && "vc-header-search-active"
          }`}
        >
          <HeaderSearch
            inputFocusProp={inputFocusProp}
            setSearchHeader={setSearchHeader}
          />
        </Col>

        {!searchHeader && <HeaderText />}

        <Col>
          <Row align="middle">
            <Col className="vc-mr-sm-0 vc-mr-4 vc-d-flex-center">
              {!searchHeader ? (
                <Button
                  type="text"
                  icon={
                    <Search set="curved" className="vc-text-color-black-60" />
                  }
                  onClick={() => searchClick()}
                />
              ) : (
                <Button
                  type="text"
                  icon={
                    <RiCloseLine size={24} className="vc-text-color-black-60" />
                  }
                  onClick={() => setSearchHeader(false)}
                />
              )}
            </Col>

            <Col className="vc-d-flex-center vc-mr-4">
              <Badge count={notifData?.length} dot offset={[-20, 10]}>
                <Button
                  type="text"
                  className="vc-mr-8"
                  onClick={() => history.push("/notification")}
                  icon={
                    <Notification
                      size={24}
                      className="vc-text-color-black-60"
                    />
                  }
                />
              </Badge>
            </Col>

            <Col className="vc-d-flex-center vc-mr-4">
              <HeaderTheme />
            </Col>

            <HeaderUser />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header>
      <Row justify="center" className="vc-w-100">
        {customise.contentWidth == "full" && (
          <Col span={24}>{headerChildren()}</Col>
        )}

        {customise.contentWidth == "boxed" && (
          <Col xxl={20} xl={22} span={24}>
            {headerChildren()}
          </Col>
        )}
      </Row>
    </Header>
  );
}
