import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Badge, Menu } from "antd";

import navigation from "../../../../navigation/vertical";
import { getAll } from "../../../../utils/service";
import { API_URL } from "../../../../api/config";
import { useWebSocketNotif } from "../../../../WebSocketNotifContext";

const { SubMenu } = Menu;

export default function MenuItem(props) {
  // Location
  const location = useLocation();
  const { pathname } = location;

  const { onClose } = props;
  const webSocketNotif = useWebSocketNotif();

  // Redux
  const customise = useSelector((state) => state.customise);
  const data = JSON.parse(localStorage.getItem("loginSession"));
  const permissions = data?.access;

  // Check KYC Status
  const [mitraData, setMitraData] = useState(0);

  const getMitraSummary = async () => {
    const res = await getAll(API_URL.mitra + "/summary");
    if (res?.status === 200) {
      setMitraData(res.data.data.total_pending);
    }
  };

  const [ticketData, setTicketData] = useState(0);

  const getTicketSummary = async () => {
    const res = await getAll(API_URL.ticket + "/indicator");
    if (res?.status === 200) {
      setTicketData(res?.data?.data);
    }
  };

  const [feedbackData, setFeedbackData] = useState(0);

  const getFeedbackSummary = async () => {
    const res = await getAll(API_URL.feedback + "/all?is_read=false");
    if (res?.status === 200) {
      setFeedbackData(res?.data?.manifest?.total);
    }
  };

  useEffect(() => {
    getMitraSummary();
    getTicketSummary();
    getFeedbackSummary();
  }, [webSocketNotif]);

  useEffect(() => {
    if (pathname.includes("/feedback")) {
      setFeedbackData(0);
    }
  }, [location]);

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  const menuItem = navigation.map((item, index) => {
    if (item.header) {
      if (
        permissions?.some((data) => item.access?.includes(data)) ||
        item.access === null
      ) {
        return (
          <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>
        );
      }
    }

    if (item.children) {
      if (
        permissions?.some((data) => item.access?.includes(data)) ||
        item.access === null
      ) {
        return (
          <SubMenu key={item.id} icon={item.icon} title={item.title}>
            {item.children.map((childrens, index) => {
              if (!childrens.children) {
                const childrenNavLink = childrens.navLink.split("/");
                if (
                  permissions?.some((data) =>
                    childrens.access?.includes(data)
                  ) ||
                  childrens.access === null
                ) {
                  return (
                    // Level 2
                    <Menu.Item
                      key={childrens.id}
                      className={
                        splitLocationUrl ===
                        childrenNavLink[childrenNavLink.length - 2] +
                          "/" +
                          childrenNavLink[childrenNavLink.length - 1]
                          ? "ant-menu-item-selected"
                          : "ant-menu-item-selected-in-active"
                      }
                      onClick={onClose}
                    >
                      <Link to={childrens.navLink}>{childrens.title} </Link>
                    </Menu.Item>
                  );
                }
              } else {
                return (
                  // Level 3
                  <SubMenu key={childrens.id} title={childrens.title}>
                    {childrens.children.map((childItem, index) => {
                      const childrenItemLink = childItem.navLink.split("/");

                      return (
                        <Menu.Item
                          key={childItem.id}
                          className={
                            splitLocationUrl ===
                            childrenItemLink[childrenItemLink.length - 2] +
                              "/" +
                              childrenItemLink[childrenItemLink.length - 1]
                              ? "ant-menu-item-selected"
                              : "ant-menu-item-selected-in-active"
                          }
                          onClick={onClose}
                        >
                          <Link to={childItem.navLink}>{childItem.title}</Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
            })}
          </SubMenu>
        );
      }
    } else {
      if (
        permissions?.some((data) => item.access?.includes(data)) ||
        item.access === null
      ) {
        const itemNavLink = item.navLink.split("/");

        return (
          // Level 1
          <Menu.Item
            key={item.id}
            icon={item.icon}
            onClick={onClose}
            className={
              splitLocation[splitLocation.length - 2] +
                "/" +
                splitLocation[splitLocation.length - 1] ===
              itemNavLink[itemNavLink.length - 2] +
                "/" +
                itemNavLink[itemNavLink.length - 1]
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"
            }
          >
            {item.indicator ? (
              <>
                <div className="vc-d-flex vc-d-flex-between vc-align-items-center">
                  <Link to={item.navLink}>{item.title}</Link>
                  {item.indicator === "mitra-indicator" && (
                    <Badge count={mitraData} className="vc-ml-8" />
                  )}

                  {item.indicator === "ticket-indicator" && (
                    <Badge count={ticketData} className="vc-ml-8" />
                  )}

                  {item.indicator === "feedback-indicator" && (
                    <Badge count={feedbackData} className="vc-ml-8" />
                  )}
                </div>
              </>
            ) : (
              <Link to={item.navLink}>{item.title}</Link>
            )}
          </Menu.Item>
        );
      }
    }
  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5
          ? splitLocation[splitLocation.length - 3]
          : null,
        splitLocation[splitLocation.length - 2],
      ]}
      theme={customise.theme == "light" ? "light" : "dark"}
    >
      {menuItem}
    </Menu>
  );
}
